import React, { useState, useRef, ChangeEvent } from 'react';
import { AccountReadingsWrapperYearly } from './AccountReadingWrappers';
import { UcLoading } from '../common/UcLoading';
import { UcProgress } from '../common/UcProgress';
import {
    Area,
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Brush
} from 'recharts';
import { SeriesCategory, SeriesGroup, ChartInstanceOfMuiTheme, UomAxisLabel, buildSeriesData, updateReadTimestampLocalDates, fillMissingHourData, fillMissingYearData } from './CustomerSummaryChartUtils';

// @ts-ignore
import { useAccountReadings, useReadings, useReadingsGB } from './ReadingAPIEndpoint';
// @ts-ignore
import { useWeatherData, useWeatherHistorySummary } from 'components/weather/WeatherApiEndpoint';
// @ts-ignore
import { useMyOrgs } from 'components/customer/orgs/MyOrgAPIEndpoints';
// @ts-ignore
import { UsageTooltip } from 'lib/UsageTooltip';
import { useTheme, Switch, FormGroup, FormControlLabel, Grid, Typography } from '@mui/material';
// @ts-ignore
import UcLegend from 'components/common/UcLegend';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useResponsive } from '../../lib/useResponsive';
// @ts-ignore
import { ensureLuxonDate, fixDataKeyName, getPrecipUnit } from '../../lib/Formatters';
// @ts-ignore
import { UcTouch } from 'components/common/UcTouch';
// @ts-ignore
import { AxisCalc } from 'lib/AxisCalc';
// @ts-ignore
import { useUserPref } from "components/user/useUserPref";
// @ts-ignore
import { MD5 } from 'object-hash';
// @ts-ignore
import { saveAs } from 'file-saver';
import Alert from '@mui/material/Alert';
import useOrgAppSettings from 'lib/useOrgAppSettings';
import CustomTick from './CustomTick';

export const CustomerSummaryChart = (props: any) => {
    // Refs
    const isDataLoaded = useRef(false);
    
    // Hooks
    const theme = useTheme<ChartInstanceOfMuiTheme>();
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const { orgAppSettings } = useOrgAppSettings();
    const showCost = useUserPref("showCost", false);
    const showTemp = {valueBool : true}; // can use preferences in the future if toggle is brought back to usage page, using: useUserPref("showTemp")
    const showPrecip = useUserPref("showPrecip", false);
    const swrMyOrg = useMyOrgs();

    // Props
    const propsHash: string = MD5(props);
    const endDatePlusFiveMinutes = new Date(props.endDate);
    endDatePlusFiveMinutes.setMinutes(endDatePlusFiveMinutes.getMinutes() + 5);
    const endDatePlusOneHour = new Date(props.endDate);
    endDatePlusOneHour.setTime(endDatePlusOneHour.getTime() + (1 * 60 * 60 * 1000));
    const endDatePlusOneMonth = new Date(props.endDate);
    endDatePlusOneMonth.setMonth(endDatePlusOneMonth.getMonth() + 1);
    const uom: string = props.uom || "KWH";
    const groupBy: string = props.groupBy || "month";
    const chartMinHeight: string = props.chartMinHeight || (isMobile ? "450px" : "600px");
    const endYear: number = ensureLuxonDate(props.endDate).year;
    const endMonth: number = ensureLuxonDate(props.endDate).month;
    const selectedMeter: string = props.selectedMeter || "All";

    // States
    const [progress, setProgress] = useState({ current: 0, total: 100 });
    const [series, setSeries] = useState<any[]>([]);
    const [categories, setCategories] = useState<SeriesCategory[]>([]);
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [isDownloadReady, setIsDownloadReady] = useState<boolean>(false);
    const [requestXmlDownload, setRequestXmlDownload] = useState<string>(props.requestXmlDownload);
    const [hasError, setHasError] = useState<boolean>(false);
    const [currentPropHash, setCurrentPropHash] = useState(propsHash);
    const [usageMinDomain, setUsageMinDomain] = useState<number>(0);
    const [usageMaxDomain, setUsageMaxDomain] = useState<number>(200000);
    const [costMinDomain, setCostMinDomain] = useState<number>(0);
    const [costMaxDomain, setCostMaxDomain] = useState<number>(200);
    const [tempMinDomain, setTempMinDomain] = useState<number>(0);
    const [tempMaxDomain, setTempMaxDomain] = useState<number>(0);
    const [yearlyData, setYearlyData] = useState<any>(null);
    const [totalColumns, setTotalColumns] = useState<number>(24);
    const [columnsToShow, setColumnsToShow] = useState<number>(13);
    const [startChartScroll, setStartChartScroll] = useState<number>(totalColumns - columnsToShow);
    const [endChartScroll, setEndChartScroll] = useState<number>(totalColumns - 1);
    const [seriesHasRateData, setSeriesHasRateData] = useState<boolean>(false);

    // SWR
    const swrWeatherData = useWeatherData(props.startDate, endDatePlusOneMonth, props.geoCoordinates, true, { doToast: false });
    const swrWeatherHistorySummary = useWeatherHistorySummary(props.startDate, props.endDate, props.geoCoordinates, true, { doToast: false });
    let swrReadings: any = null;

    const accountReadings = useAccountReadings(props.startDate, endDatePlusFiveMinutes, groupBy, uom, props.accountId, null, null, false, {}, groupBy !== "month", null, null, selectedMeter); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    const serialReadings = useReadings(props.startDate, endDatePlusFiveMinutes, groupBy, uom, props.accountId, selectedMeter, false); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    
    if (selectedMeter !== "All" && selectedMeter !== null) {
        swrReadings = serialReadings;
    }
    else {
        swrReadings = accountReadings;
    }
    const swrGBReadings = useReadingsGB(props.startDate, props.endDate, groupBy, uom, props.accountId, isDownloadReady);
    const hasWeatherData: boolean = (swrWeatherData?.result && swrWeatherData?.result?.days);

    // Other
    const numberInParenthesisRegex: RegExp = new RegExp(/ \(([0-9]*[.])?[0-9]+\)/);
    const yearlyDataCount: number = yearlyData?.mainPeriod?.readings?.length;
    let orgTempUnit: string | null = null;

    React.useEffect(() => {
        switch (groupBy) {
            case "day":
                if (isMobile) {
                    setColumnsToShow(14);
                    setStartChartScroll(Math.max(totalColumns - (14), 0));
                }
                else {
                    setColumnsToShow(31);
                    setStartChartScroll(Math.max(totalColumns - (31), 0));
                }
                setYearlyData(null);
                setHasError(false);
                break;
            case "none":
                if (isMobile) {
                    setColumnsToShow(13);
                    setStartChartScroll(Math.max(totalColumns - (13), 0));
                }
                else {
                    setColumnsToShow(24);
                    setStartChartScroll(Math.max(totalColumns - (24), 0));
                }
                setYearlyData(null);
                setHasError(false);
                break;
            default:
                setColumnsToShow(13);
                setStartChartScroll(Math.max(totalColumns - (13), 0));
                break;
        };
    }, [totalColumns, groupBy, isMobile]);

    React.useEffect(() => {
        if (requestXmlDownload !== props.requestXmlDownload && props.requestXmlDownload > 0) {
            setRequestXmlDownload(props.requestXmlDownload);
            setIsDownloadReady(true);
            props.isXmlGenerating(true);
        }

        const hasResults = groupBy === "month" ? yearlyData?.mainPeriod : swrReadings.result;

        if (hasResults && initialLoad) {
            setInitialLoad(false);
        }
    }, [swrReadings.result, swrReadings.result?.mainPeriod?.groupings, initialLoad, requestXmlDownload, props.requestXmlDownload, props, groupBy, yearlyData?.mainPeriod]);


    const updateRateUom = (uom: string, rateRules: any) => {
        if (uom?.toUpperCase() === "KWH") {
            props.setRateCategories(rateRules?.KWH);
        }
        else if (uom?.toUpperCase() === "GAL") {
            props.setRateCategories(rateRules?.GAL);
        }
        else {
            props.setRateCategories(null);
        }
    }

    const updateTempDomain = () => {
        if (orgTempUnit === "F") {
            setTempMaxDomain(90);
            setTempMinDomain(10);
        }
        else {
            setTempMaxDomain(40);
            setTempMinDomain(-10);
        }
    }

    const getFileName = () => {
        switch (groupBy) {
            case "month":
                return `YearlyUsageData_${props.accountNumber}_${props.formattedCurrentDate}.xml`;
            case "day":
                return `MonthlyUsageData_${props.accountNumber}_${props.formattedCurrentDate}.xml`;
            case "none":
                return `DailyUsageData_${props.accountNumber}_${props.formattedCurrentDate}.xml`;
            default:
                return `GBUsageData_${props.accountNumber}_${props.formattedCurrentDate}.xml`;
        }
    }

    const defaultDayChartScrolls = (newSeries: any) => {
        if ((newSeries.length - columnsToShow) > 0 && isMobile) {
            let index = newSeries.map((e: any) => e.localTimestamp).indexOf("21:00:00");

            if (index > 0) {
                setStartChartScroll(Math.max(index - columnsToShow + 1, 0));
                setEndChartScroll(index);
            }
            else {
                setStartChartScroll(Math.max(newSeries.length - columnsToShow - 3, 0));
                setEndChartScroll(newSeries.length - 4);
            }
        }
        else {
            setStartChartScroll(0); // less columns with data than columns to display
            setEndChartScroll(newSeries.length - 1);
        }
    }

    const handleSetSeries = (newSeries: any[]) => {
        const seriesStringified = JSON.stringify(newSeries);
        setSeriesHasRateData(seriesStringified?.includes('_rate'));
        setSeries(newSeries);
    }

    if (!swrMyOrg.isLoading && swrMyOrg.result?.length > 0) {
        orgTempUnit = swrMyOrg.result[0].temperatureUnit;
    }

    if (isDownloadReady && swrGBReadings.result) {
        let xmlResult = swrGBReadings.result;
        let blob = new Blob([xmlResult], { type: "text/plain;charset=utf-8" });
        saveAs(blob, getFileName());
        setIsDownloadReady(false);
        props.isXmlGenerating(false);
    }
    if (currentPropHash !== propsHash) {
        setCurrentPropHash(propsHash);
        isDataLoaded.current = false;
    }


    if ((groupBy === "month" && !(yearlyData?.mainPeriod)) ||
        (groupBy !== "month" && swrReadings.isLoading) || swrWeatherData.isLoading) {
        isDataLoaded.current = false;
    }
    else if (((groupBy === "month" && yearlyData?.mainPeriod) ||
        (groupBy !== "month" && (swrReadings?.result && swrReadings?.result?.mainPeriod))) && !isDataLoaded.current) {
        let newSeries: any[] = [];
        let totalReadingValues: any[] = [];
        let totalCostValues: any[] = [];
        let newMaxDomain: number = 0;
        let newMinDomain: number = 0;
        let newMaxCostDomain: number = 0;
        let newMinCostDomain: number = 0;
        let totalReadingValue: number = 0;
        let totalCostValue: number = 0;
        const mainPeriod = groupBy === "month" ? yearlyData.mainPeriod : swrReadings.result.mainPeriod;
        updateRateUom(uom, mainPeriod.rateRules);
        updateTempDomain();
        updateReadTimestampLocalDates(mainPeriod.readings, groupBy);  

        mainPeriod.readings.forEach((reading: any) => {
            buildSeriesData({
                series: newSeries,
                totalReadingValues: totalReadingValues,
                totalCostValues: totalCostValues,
                reading: reading,
                groupBy: groupBy,
                showEST: props.showEST,
                translator: t,
                alterSeriesTemperatureProps: {
                    hasWeatherData: hasWeatherData,
                    groupBy: groupBy,
                    series: newSeries,
                    reading: reading,
                    showStackedAvg: props.showStackAvg,
                    weatherHistorySummaryData: swrWeatherHistorySummary?.result,
                    weatherDataDays: swrWeatherData?.result?.days
                }
            });

            if (reading.showOnUsageChart) {
                totalReadingValues[reading.readTimestampLocal.ts] += reading.readingValue;
                totalReadingValue = totalReadingValues[reading.readTimestampLocal.ts];
            }

            totalCostValues[reading.readTimestampLocal.ts] += reading.cost;
            totalCostValue = totalCostValues[reading.readTimestampLocal.ts];

            // in case of undefined reading (will cause an error if unhandled), skip
            if (!newSeries[reading.readTimestampLocal.ts]) {
                return;
            }

            newSeries[reading.readTimestampLocal.ts]["totalUsage"] = totalReadingValue;
            newSeries[reading.readTimestampLocal.ts]["Cost"] = totalCostValue;
            newSeries[reading.readTimestampLocal.ts][`${fixDataKeyName(reading.uniqueName)}_rate`] = reading.rate;
            newSeries[reading.readTimestampLocal.ts][`${fixDataKeyName(reading.uniqueName)}_rateChargeType`] = reading.rateChargeType.value;
            newSeries[reading.readTimestampLocal.ts][`${fixDataKeyName(reading.uniqueName)}_showOnToolTip`] = reading.rate !== 0;

            if (totalReadingValue > newMaxDomain) {
                newMaxDomain = totalReadingValue;
            }

            if (totalReadingValue < newMinDomain) {
                newMinDomain = totalReadingValue;
            }
        });

        const domainTotalCostValues = Object.values(totalCostValues);
        
        newMaxCostDomain = Math.max(...domainTotalCostValues);
        newMinCostDomain = Math.min(...domainTotalCostValues);

        totalReadingValues = [];

        if (groupBy === "none") {
            //if in day view, fill the missing data and no need to sort
            newSeries = fillMissingHourData(newSeries, props.startDate, endDatePlusOneHour, hasWeatherData, swrWeatherData?.result?.days);
        }
        else if (groupBy === "month") {
            newSeries = fillMissingYearData(newSeries, props.startDate, endDatePlusOneHour, hasWeatherData, swrWeatherHistorySummary?.result);
        }
        else {
            newSeries = Object.keys(newSeries).sort().map((key: any) => {
                return newSeries[key];
            });
        }

        let maxDate = new Date().toISOString();
        let minDate = new Date().toISOString();
        if (newSeries?.length > 0) {
            const validMaxDates = newSeries
                .map((e: any) => new Date(e.billingPeriodEnd))
                .filter((date: Date) => !isNaN(date.getTime()));    
            if (validMaxDates.length > 0) {
                maxDate = new Date(Math.max(...validMaxDates.map(date => date!.getTime())))
                    .toISOString();
            }

            const validMinDates = newSeries
                .map((e: any) => new Date(e.billingPeriodStart))
                .filter((date: Date) => !isNaN(date.getTime())); 
            if (validMinDates.length > 0) {
                minDate = new Date(Math.min(...validMinDates.map(date => date!.getTime())))
                    .toISOString();
            }
        }

        props.setDateRangeStartText(minDate);
        props.setDateRangeEndText(maxDate);

        const axisCalc = new AxisCalc(newMinDomain, newMaxDomain);
        const axisCalcCost = new AxisCalc(newMinCostDomain, newMaxCostDomain);

        // only want to use negative numbers if we actuall have negative numbers
        if (newMinDomain < 0) {
            setUsageMinDomain(axisCalc.min_value);
        }
        else {
            setUsageMinDomain(0);
        }

        if (newMinCostDomain < 0) {
            setCostMinDomain(axisCalcCost.min_value);
        }
        else {
            setCostMinDomain(0);
        }

        if (groupBy === "none") {
            defaultDayChartScrolls(newSeries);
        }
        else {
            setStartChartScroll(Math.max(newSeries.length - (columnsToShow), 0)); // don't want a negative number here
            setEndChartScroll(newSeries.length - 1);
        }

        setUsageMaxDomain(axisCalc.max_value);
        setCostMaxDomain(axisCalcCost.max_value);
        setTotalColumns(newSeries.length);
        handleSetSeries(newSeries);
        setCategories(mainPeriod.groupings);

        if (mainPeriod.readings.length > 0) {
            props.onCsvReady(newSeries, orgTempUnit);
        }

        isDataLoaded.current = true;
    }

    const handleChangeShowCost = (event: ChangeEvent<HTMLInputElement>) => {
        showCost.setUserPref("showCost", !(showCost.valueBool));
        if (showPrecip.valueBool) {
            showPrecip.setUserPref("showPrecip", false);
        }
    }

    const handleChangeShowPrecip = (event: ChangeEvent<HTMLInputElement>) => {
        showPrecip.setUserPref("showPrecip", !(showPrecip.valueBool));
        if (showCost.valueBool) {
            showCost.setUserPref("showCost", false);
        }  
    }

    const groups: SeriesGroup[] = categories?.map((category: SeriesCategory) => ({
        dataKeyName: fixDataKeyName(category.uniqueName),
        uniqueName: category.uniqueName,
        timeOfUseName: category.timeOfUseName,
        timeOfUseRate: category.timeOfUseRate,
        timeOfUseHtmlColor: category.timeOfUseHtmlColor,
        showOnUsageChart: category.showOnUsageChart 
    }));

    let fullGroups: SeriesGroup[] = Object.values(
        groups?.reduce((arr: any, item: any) => {
            arr[item.dataKeyName] = arr[item.dataKeyName]
                ? { ...item, timeOfUseRate: item.timeOfUseRate }
                : item;
            return arr;
        }, {})
    );
    fullGroups.sort((a: any, b: any) => b.showOnUsageChart - a.showOnUsageChart) // This will put any false values of "showOnUsageChart" to the end of the list to display on the graph properly

    const newItems = series.map(item => {
        const newItem = { ...item }
        const keys = Object.keys(newItem)
        keys.forEach(key => {
            const hasNumberInParenthesis = numberInParenthesisRegex.test(key);
            if (hasNumberInParenthesis) {
                const newKey = key.replace(numberInParenthesisRegex, '')
                newItem[newKey] = (newItem[newKey] || 0) + newItem[key];
                newItem[newKey] = Math.round(newItem[newKey] * 100) / 100;
                delete newItem[key]
            }
        })
        return newItem
    });

    const uomAxisLabel: { [key: string]: UomAxisLabel } = {
        'KWH': t("Electricity ({{uom}})", { uom: orgAppSettings?.result?.uomMappings['KWH']?.safeUomDisplay }),
        'GAL': t("Water ({{uom}})", { uom: orgAppSettings?.result?.uomMappings['GAL']?.safeUomDisplay }),
    }

    function formatTemperatureAxis(value: any) {
        return value + String.fromCharCode(176);
    }

    newItems?.forEach((value) => {
        if (value.Temperature > tempMaxDomain) {
            setTempMaxDomain(value.Temperature);
        }
        if (value.Temperature < tempMinDomain) {
            setTempMinDomain(value.Temperature);
        }
    });

    const chartRef = useRef(null);
    const [barWidth, setBarWidth] = useState(0);

    React.useEffect(() => {
        const chartElement: any = chartRef.current;

        if (chartElement) {
            const barElements = Array.from(chartElement.querySelectorAll('.recharts-layer.recharts-bar-rectangle'));

            const validBarElement = barElements.find((element) => {
                const width = (element as HTMLElement).getBoundingClientRect().width;
                return width > 0;
            });

            if (validBarElement) {
                const width = (validBarElement as HTMLElement).getBoundingClientRect().width;
                setBarWidth(width);
            }
        }
    }, [newItems]);

    const axisTempCalc = new AxisCalc(tempMinDomain, tempMaxDomain);
    const useCustomTick = groupBy === 'none'; // Hourly view only

    return (
        <div className="customerSummaryTab customerSummaryTabMonthly graphWrapper">

            {groupBy === "month" &&
                <AccountReadingsWrapperYearly
                    accountId={props.accountId}
                    uom={uom}
                    selectedMeter={selectedMeter}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    month={endMonth}
                    year={endYear}
                    readyForData={!hasError}
                    onDataCompleted={(newData) => {
                        if (yearlyDataCount !== newData?.mainPeriod?.readings?.length && isDataLoaded.current) {
                            isDataLoaded.current = false;
                        }
                        setYearlyData(newData);
                        setHasError(false);
                    }}
                    onProgress={(progressLocal) => {
                        if (progressLocal.current !== progress.current) {
                            setProgress({ ...progressLocal });
                        }
                    }}
                    onReset={() => {
                        setYearlyData(null);
                        setHasError(false);
                    }}
                    onError={(error) => {
                        console.error(error);
                        setHasError(true);
                    }} />
            }
            <UcLoading hasError={hasError} isLoading={!isDataLoaded.current && swrReadings.isLoading} loadingRender={<UcProgress {...progress} />} >
                <div className="loadingChartWrapper">
                    {
                        ((!yearlyData?.mainPeriod && !swrReadings?.result?.mainPeriod) || !seriesHasRateData) ? (
                            <Alert severity="warning" sx={{ mx: 5, mb: 40, mt: 10 }}>{t("No data available.")}</Alert>
                        ) : (
                            <>
                                {isMobile && (
                                    <Grid container>
                                        <Grid item xs={6} justifyContent="left" paddingLeft="5px">
                                            <Typography fontSize="16px" color={theme?.props.Rechart.axisColor}> {orgAppSettings?.result?.uomMappings[uom]?.safeUomDisplay} </Typography>
                                        </Grid>
                                        {showCost.valueBool && (
                                            <Grid item xs={6} justifyContent="right" alignItems="right" display="flex" paddingRight="5px">
                                                <Typography fontSize="16px" color={theme?.props.Rechart.axisColor}> {t("Cost ($)")} </Typography>
                                            </Grid>
                                        )}
                                        {showPrecip.valueBool && (
                                            <Grid item xs={6} justifyContent="right" alignItems="right" display="flex" paddingRight="5px">
                                                <Typography fontSize="16px" color={theme?.props.Rechart.axisColor}> {t(`Precipitation (${getPrecipUnit(orgTempUnit)})`)} </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                                <UcTouch
                                    style={{ height: "100%", width: "100%" }}
                                    columnsToShow={columnsToShow}
                                    totalColumns={totalColumns}
                                    startX={startChartScroll}
                                    onRangeChangeX={(newStart: any, newEnd: any) => {
                                        setStartChartScroll(Math.max(newStart, 0));
                                        setEndChartScroll(newEnd);
                                        if (newItems) {
                                            handleSetSeries([...newItems])
                                        }
                                    }}
                                    >
                                    <div ref={chartRef} className={"myCustomTickDivRef"}>
                                        <ResponsiveContainer
                                            width="100%"
                                            height="100%"
                                            minHeight={chartMinHeight}
                                            className="recharts-container-scrollable">
                                            <ComposedChart
                                                data={newItems}
                                                margin={{
                                                    top: (isMobile ? 5 : 20),
                                                    right: (isMobile ? 5 : 20),
                                                    bottom: 5,
                                                    left: (isMobile ? -15 : -20)
                                                }}
                                                // @ts-ignore
                                                fillOpacity="0.7"
                                            >
                                                <CartesianGrid stroke={theme?.props?.Rechart?.cartesianGridColor} strokeDasharray="5 5" />
                                                <XAxis
                                                    dataKey="name"
                                                    interval={isMobile ? 1 : 0}
                                                    angle={-45}
                                                    textAnchor="end"
                                                    tick={useCustomTick ? <CustomTick {...props} barWidth={barWidth} /> : { fill: theme?.props?.Rechart?.axisColor, fontSize: 12 }}
                                                    tickLine={!useCustomTick}
                                                />
                                                <YAxis yAxisId="usage" tick={{ fill: theme?.props?.Rechart?.axisColor, fontSize: (isMobile ? '12px' : '16px') }} label={{ value: isMobile ? "" : uomAxisLabel[uom], angle: -90, position: "left", offset: (isMobile ? -10 : 12), style: { fill: theme?.props?.Rechart?.axisColor, fontSize: (isMobile ? '12px' : '16px') } }}
                                                    domain={[usageMinDomain, usageMaxDomain]} allowDataOverflow={true} axisLine={!isMobile} />
                                                <YAxis yAxisId="usageEst" display={"none"} hide={isMobile} />

                                                <YAxis yAxisId="temperature" tickFormatter={formatTemperatureAxis} hide={isMobile} orientation="right" tick={{ fill: theme?.props?.Rechart?.axisColor }} allowDataOverflow={props.showStackedAvg ? false : true}
                                                    label={{ value: (orgTempUnit === "F" ? `Temperature (${String.fromCharCode(176)}F)` : `Temperature (${String.fromCharCode(176)}C)`), angle: -90, position: "insideRight", offset: 18, style: { fill: theme?.props?.Rechart?.axisColor, visibility: showTemp.valueBool ? "visible" : "hidden" } }}
                                                    domain={[axisTempCalc.min_value, axisTempCalc.max_value]} />
                                                <YAxis yAxisId="Cost" hide={showCost.valueBool ? false : true} orientation="right" tick={{ fill: theme?.props?.Rechart?.axisColor, fontSize: (isMobile ? '12px' : '16px') }}
                                                    label={{ value: isMobile ? "" : "Cost ($)*", angle: -90, position: "insideRight", style: { fill: theme?.props?.Rechart?.axisColor, visibility: showCost.valueBool ? "visible" : "hidden" }, transform: 'translateX(12px)' }}
                                                    domain={[costMinDomain, costMaxDomain]} axisLine={!isMobile} />
                                                <YAxis yAxisId="precip" hide={showPrecip.valueBool ? false : true} orientation="right" tick={{ fill: theme?.props?.Rechart?.axisColor, fontSize: (isMobile ? '12px' : '16px') }}
                                                    label={{ value: isMobile ? "" : `Precipitation (${getPrecipUnit()})`, angle: -90, position: "insideRight", style: { fill: theme?.props?.Rechart?.axisColor, visibility: showPrecip.valueBool ? "visible" : "hidden" }, transform: 'translateX(12px)' }}
                                                    axisLine={!isMobile} />

                                                {/* TODO: Convert UsageTooltip to TS */}
                                                {/* @ts-ignore */}
                                                <Tooltip trigger='click' labelStyle={{ color: 'black' }} data={series} content={UsageTooltip} meterUom={orgAppSettings?.result?.uomMappings[props.uom?.toUpperCase()]?.safeUomDisplay} orgTempUnit={orgTempUnit} groupBy={groupBy} />
                                                <Legend content={UcLegend} />

                                                <Area yAxisId="temperature" stackId="1" type="monotone" dataKey="Chart-Only-Area-Stack-Hidden" legendType={"none"} stroke="blue" fill="blue" style={{ visibility: "hidden" }} />
                                                <Area yAxisId="temperature" stackId="1" type="monotone" dataKey="Chart-Only-Area-Stack-Visible" legendType={"none"} fill="#ffc658" activeDot={{ r: 8 }} style={{ visibility: showTemp.valueBool ? "visible" : "hidden", fillOpacity: "0.2" }} stroke="#ffc658" />

                                                {fullGroups.map((grouping, index) => {
                                                    return (
                                                        <Bar
                                                            key={"customerSummaryPageCategories_" + grouping.uniqueName}
                                                            yAxisId="usage"
                                                            isAnimationActive={false}
                                                            stackId="usage"
                                                            dataKey={grouping.dataKeyName}
                                                            fill={grouping.timeOfUseHtmlColor}
                                                            display={(!grouping.showOnUsageChart ? "none" : "inherit")}
                                                            legendType={!grouping.showOnUsageChart ? "none" : "circle"} />
                                                    );
                                                })}

                                                {fullGroups.map((grouping, index) => {
                                                    return (
                                                        <Line isAnimationActive={false} key={"customerSummaryPageCategories_" + grouping.uniqueName} legendType={"none"} yAxisId="usageEst" dataKey={t("EST {{estimatedItem}}", { estimatedItem: grouping.timeOfUseName })} stroke={grouping.timeOfUseHtmlColor} activeDot={{ r: 0 }} />);
                                                })}

                                                <Line yAxisId="temperature" isAnimationActive={false} dataKey="Temperature" type="monotone" fill="#ffc658" stroke="#ffc658" activeDot={{ r: 8 }} style={{ visibility: "hidden" }} />
                                                {(groupBy === "month" || groupBy === "day") &&
                                                    <>
                                                        <Line yAxisId="temperature" isAnimationActive={false} dataKey="High-Temperature" legendType={"none"} type="monotone" fill="red" stroke="red" activeDot={{ r: props.showStackedAvg ? 8 : 0 }} style={{ visibility: "hidden" }} />
                                                        <Line yAxisId="temperature" isAnimationActive={false} dataKey="Low-Temperature" legendType={"none"} type="monotone" fill="blue" stroke="blue" activeDot={{ r: props.showStackedAvg ? 8 : 0 }} style={{ visibility: "hidden" }} />
                                                    </>
                                                }

                                                <Line yAxisId="Cost" isAnimationActive={false} dataKey="Cost" type="monotone" fill="#8884d8" stroke="#8884d8" activeDot={{ r: showCost.valueBool ? 8 : 0 }} legendType={showCost.valueBool ? "line" : "none"} style={{ visibility: showCost.valueBool ? "visible" : "hidden" }} />
                                                <Line yAxisId="precip" name="Precipitation" isAnimationActive={false} dataKey="Precip" type="monotone" fill="#4287f5" stroke="#4287f5" activeDot={{ r: showPrecip.valueBool ? 8 : 0 }} legendType={showPrecip.valueBool ? "line" : "none"} style={{ visibility: showPrecip.valueBool ? "visible" : "hidden" }} />
                                                <Brush
                                                    updateId={startChartScroll}
                                                    startIndex={startChartScroll}
                                                    endIndex={endChartScroll}
                                                    dataKey="name"
                                                />
                                            </ComposedChart>
                                        </ResponsiveContainer>
                                    </div>
                                </UcTouch>
                                <FormGroup className="form-inline" style={{ flexDirection: 'row', paddingRight: "15px", justifyContent: 'flex-end' }}>
                                    <FormControlLabel
                                        control={<Switch checked={(showCost.valueBool)}
                                                    onChange={handleChangeShowCost}
                                                    inputProps={{ 'aria-label': 'controlled' }} />
                                                }
                                        labelPlacement="start"
                                        label={t("Cost ($)*")}
                                    />
                                </FormGroup>
                                <FormGroup className="form-inline" style={{ flexDirection: 'row', paddingRight: "15px", justifyContent: 'flex-end' }}>
                                    <FormControlLabel
                                        control={<Switch checked={(showPrecip.valueBool)}
                                                onChange={handleChangeShowPrecip}
                                                inputProps={{ 'aria-label': 'controlled' }} />
                                            }
                                        labelPlacement="start"
                                        label={t(`Precipitation (${getPrecipUnit()})`)}
                                    />
                                </FormGroup>           
                                <Typography color={theme.palette.primary.light} sx={{
                                    justifyContent: "right",
                                    alignItems: "right",
                                    width: "100%",
                                    display: "flex",
                                    pr: "15px",
                                    pb: "10px",
                                    fontStyle: "italic",
                                    fontSize: "13px"
                                }}>
                                    {t("* Actual charges may vary")}
                                </Typography>
                            </>
                        )}

                </div>
            </UcLoading>
        </div>
    );
};