import React, { useEffect } from "react";
// @ts-ignore
import { useAccountReadings, useReadings } from "./ReadingAPIEndpoint";
import { UcProgressStats } from "components/common/UcProgress";
import { FetcherOptions } from "./FetcherOptions";

interface AccountReadingsWrapperYearlyOptions extends FetcherOptions {
    startDate: Date;
    endDate: Date;
    id?: any,
    year: number,
    month: number,
    uom: string,
    selectedMeter?: string | null,
    accountId: number,
    currentRateClassId?: number | null,
    comparedRateClassId?: number | null
};

const AccountReadingsWrapperYearly = (options: AccountReadingsWrapperYearlyOptions) => {
    const [prevOptions, setPrevOptions] = React.useState(options);
    const selectedMeter = options.selectedMeter ?? null;

    useEffect(() => {
        if (options.year !== prevOptions.year || options.month !== prevOptions.month || options.accountId !== prevOptions.accountId || options.uom !== prevOptions.uom || options.selectedMeter !== prevOptions.selectedMeter) {
            setPrevOptions({...options});
            const newProgress = {current: 1, total: 100};
            setProgress(newProgress); // reset the progress
            if (options.onReset) {
                options.onReset();
            }
        }
    }, [options, prevOptions.month, prevOptions.year, prevOptions.accountId, prevOptions.uom, prevOptions.selectedMeter]);

    let accountId: any = options.accountId;
    const [progress, setProgress] = React.useState<UcProgressStats>({current: 0, total:100});

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress.current >= progress.total) {
                clearInterval(interval);
                return;
            }
            // Make it so it is never 100%.. this is a fake progress
            const newProgress = {current: progress.current+1, total: progress.total+1};
            setProgress(newProgress);
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }, 200);
        return () => { clearInterval(interval); }
    }, [options, progress]);

    let data: any = null;

    const accountReadings = useAccountReadings(options.startDate, options.endDate, "month", options.uom, accountId, null, null, false, {}, options.readyForData, options.currentRateClassId, options.comparedRateClassId, selectedMeter);
    const serialReadings = useReadings(options.startDate, options.endDate, "month", options.uom, accountId, selectedMeter, false); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    
    if (selectedMeter !== "All" && selectedMeter !== null) {
        data = serialReadings;
    }
    else {
        data = accountReadings;
    }
    
    React.useEffect(() => {
        if (!data.error && !data.isLoading && options.readyForData && progress.current !== 1 && progress.total !== 1) {
            // data.result?.map()
            // Need to find a way to map data
            if (options.onDataCompleted) {
                options.onDataCompleted(data.result);
            }
            const newProgress = {current: 1, total: 1};
            setProgress(newProgress); // just complete the progress
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }
        else if (data.error && !data.isLoading && options.readyForData && progress.current !== 1 && progress.total !== 1) {
            if (options.onError) {
                options.onError(data.error);
            }
            const newProgress = {current: 1, total: 1};
            setProgress(newProgress); // just complete the progress
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }
    }, [data, options, progress]);

    return null;
};


export {
    AccountReadingsWrapperYearly,
};