import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomerSummaryFilter } from './CustomerSummaryFilter';

export const CustomerSummaryFilterDialog = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(props.open || false);
    const [dialogSubmit, setDialogSubmit] = React.useState(0);

    const handleClose = () => {
        setOpen(false);
        props.onChangeOpen(false);
    };

    const handleSubmit = () => {
        setDialogSubmit(dialogSubmit + 1);
    }

    React.useEffect(() => {
        if (props.open !== open)
        {
            setOpen(props.open);
        }
    }, [props.open, open]);

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{display: open ? 'block' : 'none'}}
        >
            <DialogTitle id="alert-dialog-title">
                {t("Date Filter")}
            </DialogTitle>
            <DialogContent>
                <CustomerSummaryFilter
                    dialogSubmit={dialogSubmit}
                    defaultStartDate={props.startDate}
                    defaultEndDate={props.endDate}
                    chartType={props.chartType}
                    defaultValues={props.defaultValues}
                    uom={props.uom}
                    onSubmit={(values) => {
                        props.onSubmit(values);
                        handleClose();
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('Cancel')}</Button>
                <Button onClick={handleSubmit}>{t('Filter')}</Button>
            </DialogActions>
      </Dialog>
    );
};