import { Box, Typography, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useEnums } from 'components/auth/PermissionApiEndpoint';
// @ts-ignore
import { enumFormatter } from 'lib/EnumFormatter';
import { UcWidget } from './UcWidget';

export interface UcSummaryTableProps {
    uom: string;
    rateCategories: RateDetails[];
    allowedRateChargeTypes?: string[];
    titleOverride?: string;
}

export interface RateDetails {
    cepOrgId: number;
    createdTs: string;
    description: string;
    exemptForHolidays: boolean;
    htmlColor: string;
    id: number;
    isDefault: boolean;
    meterStartTimestamp: string;
    name: string;
    rate: number;
    rateChargeId: number;
    rateChargeType: {
        value: string;
    };
    rateClassId: number;
    rateStartTimestamp: string;
    rateVersionStartDate: string;
    ruleDescriptions: string[];
    showOnUsageChart: boolean;
    updatedTs: string;
}

export const UcSummaryTable = (props: UcSummaryTableProps) => {
    const { t } = useTranslation();
    const { allowedRateChargeTypes, rateCategories, uom, titleOverride } = props;
    const rateChargeTypeEnums = useEnums("RateChargeType");

    const isRateAllowed = (rate: RateDetails) => {
        let result = (
            allowedRateChargeTypes === undefined
            || allowedRateChargeTypes?.length === 0
            || allowedRateChargeTypes?.includes(rate?.rateChargeType?.value)
        );

        return result;
    }

    const filteredRateCategories = (rateCategories?.filter((rate: RateDetails) => isRateAllowed(rate)));
    const groupedItems = filteredRateCategories?.reduce((result: any, item: any) => {
        const key = item.serialNo + (item.meterNickname ? ` - ${item.meterNickname}` : '');
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);
        return result;
    }, {});

    return (
        <>
            {groupedItems !== undefined ?
                Object.keys(groupedItems)?.map((key: string) => (
                    <div style={{ marginBottom: 8 }}>
                        <UcWidget
                            key={`${key}_ rates`}
                            title={titleOverride ? titleOverride : t('Meter') + " #" + key}
                            titleFixedFontSize={18}
                            alignTitleLeft={true}
                            contentPadding={'1rem'}
                            minHeight={"100px"}
                        >
                            {groupedItems[key].map((item: any, index: number) => (
                                <>
                                    {index > 0 && (
                                        <Divider sx={{ my: 1 }} />
                                    )}
                                    <Grid container spacing={1} justifyContent={'flex-start'} alignItems={'center'}>
                                        <Grid item>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "20px",
                                                    height: "20px",
                                                    backgroundColor: `${item?.htmlColor}`,
                                                    borderRadius: "50%"
                                                }}>
                                            </div>
                                        </Grid>
                                        <Grid item xs={8} md={4}>
                                            <Typography fontWeight={'bold'} variant='body2'>
                                                {
                                                    (item?.rateChargeType?.value !== undefined)
                                                        ? enumFormatter(rateChargeTypeEnums, item?.rateChargeType?.value)
                                                        : enumFormatter(rateChargeTypeEnums, item?.rateChargeType)
                                                } - {item?.name}
                                            </Typography>
                                            <Typography variant='body2'>
                                                ${item?.rate}
                                                {(item?.rateChargeType?.value === "MONTHLY" || item?.rateChargeType === "MONTHLY") ? " per month" : "/" + uom}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Box sx={{ ml: 1 }}>
                                                {item?.ruleDescriptions?.length > 0 ? (
                                                    item?.ruleDescriptions?.map((item: string) => (
                                                        <Typography key={`${item}-description`} variant='body2'>
                                                            {'\u2022 ' + item}
                                                        </Typography>
                                                    ))
                                                ) : (
                                                    <Typography variant='body2'>- - -</Typography>
                                                )}   
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            ))}
                        </UcWidget>

                    </div>
                )) :
                <Typography sx={{ padding: "15px 25px 10px 25px" }}>
                    {t('No rates are currently available.')}
                </Typography>
            }
        </>

    );
};